<template>
	<div class="page app-container" id="watchShop">
		<div class="brand-list-title" style="border-bottom:1px solid #D8D8D8;display:flex;justify-content: space-between;margin-bottom: 10px;">
			<div style="display:inline-block;border-bottom:1px solid #323232;font-size:18px;"></div>
			<div style="margin-left: auto;">
				<el-button type="danger" size="small" @click="back">关闭</el-button>
			</div>
		</div>
		<el-table :data="list" ref="refTable" :element-loading-text="elementLoadingText" border style="height: calc(100vh - 130px);"
		 v-loading="loading">
			<el-table-column show-overflow-tooltip prop="shop" label="店名" width="300"></el-table-column>
			<el-table-column show-overflow-tooltip label="平台" width="120">
				<template slot-scope="scope">
					<div>{{getZhanDian(scope.row.zhandian)}}</div>
				</template>
			</el-table-column>
			<el-table-column show-overflow-tooltip prop="goods" label="商品名称"></el-table-column>
			<el-table-column show-overflow-tooltip prop="xiaoliang" label="销量" width="150"></el-table-column>
			<el-table-column show-overflow-tooltip prop="price" label="价格" width="120"></el-table-column>
			<el-table-column label="操作" width="160">
				<template slot-scope="scope">
					<el-dropdown trigger="click" style="cursor: pointer;" @command="empowerClick($event, scope.row)">
						<span class="el-dropdown-link" style="color: #409EFF;" v-if="radio == '1'">
							{{scope.row.empower == '0' ? '未授权' : '已授权'}}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<span class="el-dropdown-link" style="color: #409EFF;" v-else-if="radio == '2'">
							已授权<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<span class="el-dropdown-link" style="color: #409EFF;" v-else>
							未授权<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="yes">一键授权</el-dropdown-item>
							<el-dropdown-item command="no">取消授权</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<el-button type="text" style="margin-left: 40px;" @click="detailHandle(scope.row.url)">详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination style="text-align: center;margin-top: 10px;" background @size-change="handleSizeChange"
		 @current-change="handleCurrentChange" :current-page="page" :page-sizes="[10, 30, 50, 100]" :page-size="size" layout="total, sizes, prev, pager, next, jumper"
		 :total="total">
		</el-pagination>
	</div>
</template>

<script>
	import {
		goodsAll,
		goodsEmpower,
		goodsUnempower,
		empower,
		unEmpower
	} from "@/api/api";
	import * as _ from '../../utils/tool'
	export default {
		components: {},
		data() {
			return {
				sqr: '',
				list: [],
				loading: false,
				elementLoadingText: '正在加载...',
				page: 1,
				size: 30,
				total: 0,
				options: [{
						value: 'jd',
						label: '京东'
					},
					{
						value: 'atb',
						label: '淘宝'
					},
					{
						value: 'sn',
						label: '苏宁'
					},
					{
						value: 'wph',
						label: '唯品会'
					},
					{
						value: 'amazon',
						label: '亚马逊'
					},
					{
						value: 'gm',
						label: '国美'
					},
				],
				radio: '1'
			}
		},
		async created() {
			this.getList()
		},
		methods: {
			async empowerClick(command, row) {
				this.loading = true
				let data = {
					"user": this.$store.getters.UserId,
					"corpId": this.$store.getters.CorpId,
					"dlr": this.$route.params.company,
					"sqr": this.sqr || "",
					"pinpai": row.pinpai,
					"zhandian": row.zhandian,
					"shop": row.shop,
					"title": row.goods,
					"price": row.price
				}
				// console.log(JSON.stringify(data))
				let res
				if (command == 'yes') res = await empower(data)
				else res = await unEmpower(data)
				this.$message.success(res.data)
				this.getList()
			},
			radioChange(val) {
				this.restart()
				this.getList()
			},
			searchClick() {
				this.restart()
				this.getList()
			},
			getZhanDian(en) {
				let ret = this.options.filter(item => item.value == en)
				return ret[0].label
			},
			async getList() {
				let data = {
					"user": this.$store.getters.UserId,
					"corpId": this.$store.getters.CorpId,
					"dlr": this.$route.params.company,
					"sqr": this.sqr,
					"page": this.page,
					"size": this.size
				}
				this.loading = true
				let res
				if (this.radio == '1') res = await goodsAll(data)
				else if (this.radio == '2') res = await goodsEmpower(data)
				else res = await goodsUnempower(data)
				this.loading = false
				console.log(res)
				this.list = res.data.data
				this.total = res.data.count
			},
			// 表格页面变化
			handleCurrentChange(val) {
				this.page = val
				this.getList()
			},
			// 表格每页显示条数变化
			handleSizeChange(val) {
				this.restart()
				this.size = val
				this.getList()
			},
			restart() {
				this.page = 1
				this.list = new Array()
				this.total = 0
			},
			detailHandle(url) {
				if (!url) return
				window.open(url, '_blank');
			},
			back() {
				this.$router.back()
			},
		},
	}
</script>

<style scoped>
	.page {
		height: 100%;
		padding: 20px;
	}
</style>
<style>
	#watchShop .el-descriptions-item__container {
		align-items: center;
	}
</style>
